import { render, staticRenderFns } from "./ShopHomepageSections.vue?vue&type=template&id=6ad1d770&scoped=true&"
import script from "./ShopHomepageSections.vue?vue&type=script&lang=ts&"
export * from "./ShopHomepageSections.vue?vue&type=script&lang=ts&"
import style0 from "./ShopHomepageSections.vue?vue&type=style&index=0&id=6ad1d770&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ad1d770",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlusHomeTeaser: require('/app/build/components/PlusHomeTeaser/PlusHomeTeaser.vue').default,ProductsSwimlane: require('/app/build/components/ProductsSwimlane/ProductsSwimlane.vue').default,CategoriesSwimlane: require('/app/build/components/CategoriesSwimlane/CategoriesSwimlane.vue').default,CollectionsSwimlaneV2: require('/app/build/components/CollectionsSwimlane/CollectionsSwimlaneV2.vue').default,CollectionCard: require('/app/build/components/CollectionCard/CollectionCard.vue').default,MarketingBannerSlider: require('/app/build/components/MarketingBannerSlider/MarketingBannerSlider.vue').default})




























import { useContext } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'ItemUnavailableModal'
};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    store
  } = useContext();

  function continueShopping() {
    store.dispatch('checkout/resetPrepareCartForCheckoutError');
  }

  return {
    continueShopping
  };
};

export default __sfc_main;

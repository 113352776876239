import { render, staticRenderFns } from "./AddressDeleteModal.vue?vue&type=template&id=bdf5d87e&scoped=true&"
import script from "./AddressDeleteModal.vue?vue&type=script&lang=ts&"
export * from "./AddressDeleteModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddressDeleteModal.vue?vue&type=style&index=0&id=bdf5d87e&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdf5d87e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})

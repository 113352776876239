





































































import { computed, useRoute, useStore, useContext } from '@nuxtjs/composition-api';
import IRootState from '~/types/app/State';
import { ROUTES, EXPLORE_PAGES, PROFILE_PAGES } from '~/lib/routes';
import { getRouteName } from '~/lib/helpers';
import { ETrackingType, getScreenName } from '~/lib/segment';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const store = useStore<IRootState>();
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute().value;
  const CART_PRODUCT_COUNT_MAX = '99+';
  const cartProductsCount = computed<number | string>(() => {
    const count = store.getters.getCartTotalProductsCount;
    return count < 100 ? count : CART_PRODUCT_COUNT_MAX;
  });
  const isUserAuthenticated = computed<boolean>(() => store.getters['account/isUserAuthenticated']);
  const routeName = computed<string>(() => getRouteName(route));
  const isKiosk = computed(() => store.getters.getIsKiosk);
  const isProfileActive = computed<boolean>(() => PROFILE_PAGES.includes((routeName.value as ROUTES)));
  const isExploreActive = computed<boolean>(() => EXPLORE_PAGES.includes((routeName.value as ROUTES)));
  const homeIcon = computed<string>(() => isExploreActive.value ? 'explore-selected' : 'explore');
  const dealsIcon = computed<string>(() => routeName.value === ROUTES.DEALS ? 'deals-selected' : 'deals');
  const cartIcon = computed<string>(() => routeName.value === ROUTES.CART ? 'cart-selected' : 'cart');
  const profileIcon = computed<string>(() => isProfileActive.value ? 'profile-selected' : 'profile');

  function trackClick(componentVariant: string) {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: 'navigation_bar',
      componentVariant,
      screenName
    });
  }

  return {
    ROUTES,
    CART_PRODUCT_COUNT_MAX,
    cartProductsCount,
    isUserAuthenticated,
    isKiosk,
    isProfileActive,
    isExploreActive,
    homeIcon,
    dealsIcon,
    cartIcon,
    profileIcon,
    trackClick
  };
};

export default __sfc_main;

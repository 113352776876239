














import { ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  videoSrc: {
    key: "videoSrc",
    required: true,
    type: String
  },
  poster: {
    key: "poster",
    required: false,
    type: String
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const videoContainer = ref<HTMLDivElement | null>(null);
  const video = ref<HTMLVideoElement | null>(null);
  let observer: IntersectionObserver | null = null;

  const playVideo = () => {
    if (video.value) {
      video.value.play();
    }
  };

  const pauseAndResetVideo = () => {
    if (video.value) {
      video.value.pause();
      video.value.currentTime = 0;
    }
  };

  const onVideoEnded = () => {
    if (video.value) {
      video.value.currentTime = 0;
    }
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        playVideo();
      } else {
        pauseAndResetVideo();
      }
    });
  };

  const initIntersectionObserver = () => {
    observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5
    });

    if (videoContainer.value) {
      observer.observe(videoContainer.value); // Check if the element is already in the viewport when mounted

      const rect = videoContainer.value.getBoundingClientRect();
      handleIntersection([({
        isIntersecting: rect.top < window.innerHeight && rect.bottom >= 0
      } as IntersectionObserverEntry)]);
    }
  };

  onMounted(() => {
    setTimeout(() => {
      initIntersectionObserver();
    }, 500);
  });
  onBeforeUnmount(() => {
    if (observer) {
      observer.disconnect();
    }
  });
  return {
    videoContainer,
    video,
    onVideoEnded
  };
};

export default __sfc_main;


























import { ref, watch, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'ClosingSoonModal'
};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  },
  closingTime: {
    type: String,
    default: ''
  },
  isClosed: {
    type: Boolean,
    default: false
  },
  minutesBeforeShowing: {
    type: Number,
    default: 15
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const timer = ref(0);
  const delta = ref(0);
  const isClosingSoon = computed<boolean>(() => !props.shown && delta.value > 0 && delta.value <= props.minutesBeforeShowing * 60);
  const minutes = computed<number>(() => Math.floor(delta.value / 60) % 60);
  const seconds = computed<number>(() => delta.value % 60);

  function checkTime(): void {
    if (delta.value < 0 || props.isClosed || !props.closingTime) {
      stopTimer();
      return;
    }

    const [hours, minutes] = props.closingTime.split(':').map(n => parseInt(n));
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    currentDate.setHours(hours, minutes, 0, 0);
    const closingTime = currentDate.getTime();
    delta.value = Math.round((closingTime - currentTime) / 1000);
  }

  function startTimer(): void {
    timer.value = window.setInterval(checkTime, 1000);
  }

  function stopTimer(): void {
    if (timer.value !== 0) {
      emit('time-up');
      window.clearInterval(timer.value);
    }

    timer.value = 0;
    delta.value = 0;
  }

  onMounted(() => {
    if (!props.isClosed) {
      startTimer();
    }
  });
  onUnmounted(() => {
    window.clearInterval(timer.value);
  });
  watch(() => props.isClosed, () => {
    if (!props.isClosed && !timer.value) {
      startTimer();
    }
  });
  return {
    isClosingSoon,
    minutes,
    seconds
  };
};

export default __sfc_main;



































import { onBeforeUnmount, onMounted } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  closable: {
    type: Boolean,
    default: true
  },
  closeLabel: {
    type: String,
    default: ''
  },
  fillContainer: {
    type: Boolean,
    default: false
  },
  narrowContainer: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  const setMainScroll = (state: 'on' | 'off') => {
    const MAIN_ELEMENT_ID = 'app';
    const HIDE_SCROLL_CLASS = 'no-scrollbar';
    const appElement = document.getElementById(MAIN_ELEMENT_ID);

    if (state === 'on') {
      appElement?.classList.add(HIDE_SCROLL_CLASS);
    } else {
      appElement?.classList.remove(HIDE_SCROLL_CLASS);
    }
  };

  onMounted(() => {
    document.body.classList.add('locked');
    setMainScroll('off');
  });
  onBeforeUnmount(() => {
    document.body.classList.remove('locked');
    setMainScroll('on');
  });
  return {
    emit
  };
};

export default __sfc_main;







































import ICollection from '~/types/app/Collection';
import { ROUTES } from '~/lib/routes';
import { CATEGORY_SWIMLANE_V2_STYLES } from '~/lib/constants';
import { useRoute, useContext } from '@nuxtjs/composition-api';
import { EComponentName, ETrackingType, getScreenName } from '~/lib/segment';
const __sfc_main = {
  name: 'CollectionsSwimlane'
};
__sfc_main.props = {
  collections: {
    type: (Array as () => ICollection[]),
    default: () => [],
    required: true
  },
  title: {
    type: String,
    default: '',
    required: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const routeName = ROUTES.COLLECTION;
  const marginRight = CATEGORY_SWIMLANE_V2_STYLES.MARGIN_RIGHT;
  const thumbnailWidth = CATEGORY_SWIMLANE_V2_STYLES.THUMBNAIL_WIDTH;
  const thumbnailHeight = CATEGORY_SWIMLANE_V2_STYLES.THUMBNAIL_HEIGHT;
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute().value;

  function trackClick(collectionId: string, collectionTitle: string, index: number) {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.collection,
      componentVariant: collectionTitle,
      componentPosition: index + 1,
      componentContent: collectionId,
      screenName
    });
  }

  return {
    routeName,
    marginRight,
    thumbnailWidth,
    thumbnailHeight,
    trackClick
  };
};

export default __sfc_main;

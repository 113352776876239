import { render, staticRenderFns } from "./DefaultLayout.vue?vue&type=template&id=25962823&scoped=true&"
import script from "./DefaultLayout.vue?vue&type=script&lang=ts&"
export * from "./DefaultLayout.vue?vue&type=script&lang=ts&"
import style0 from "./DefaultLayout.vue?vue&type=style&index=0&id=25962823&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25962823",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorLayout: require('/app/build/components/layout/ErrorLayout/ErrorLayout.vue').default,GlobalHeader: require('/app/build/components/GlobalHeader/GlobalHeader.vue').default,ServiceInfo: require('/app/build/components/ServiceInfo/ServiceInfo.vue').default,CartButton: require('/app/build/components/cart/CartButton.vue').default,MainFooter: require('/app/build/components/MainFooter/MainFooter.vue').default,BottomNavigation: require('/app/build/components/BottomNavigation/BottomNavigation.vue').default,AgeVerification: require('/app/build/components/age-verification/AgeVerification.vue').default,StoreClosing: require('/app/build/components/StoreClosing/StoreClosing.vue').default,LocationOnboarding: require('/app/build/components/onboarding/LocationOnboarding/LocationOnboarding.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,Drawer: require('/app/build/components/Drawer/Drawer.vue').default,VOverlay: require('/app/build/components/VOverlay/VOverlay.vue').default,CartClearedModal: require('/app/build/components/CartClearedModal/CartClearedModal.vue').default,TimeSlotsModal: require('/app/build/components/TimeSlotsModal/TimeSlotsModal.vue').default,ItemUnavailableModal: require('/app/build/components/ItemUnavailableModal/ItemUnavailableModal.vue').default,PaymentModal: require('/app/build/components/PaymentModal/PaymentModal.vue').default,StorageFee: require('/app/build/components/StorageFee/StorageFee.vue').default,FeeInfoModal: require('/app/build/components/FeeInfoModal/FeeInfoModal.vue').default,GenericErrorModal: require('/app/build/components/GenericErrorModal/GenericErrorModal.vue').default})

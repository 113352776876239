


























import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { TranslateResult } from 'vue-i18n';
import { computed, useContext, onMounted } from '@nuxtjs/composition-api';
import { theme } from '~/tailwind.config';
import { EPaymentStatus } from '~/types/app/Cart';
const __sfc_main = {};
__sfc_main.props = {
  paymentStatus: {
    type: (Object as () => EPaymentStatus),
    required: true
  },
  action: {
    type: Function,
    default: () => undefined
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n,
    $segmentEvent
  } = useContext();
  const props = __props;
  onMounted(() => {
    if ([EPaymentStatus.SUCCESS, EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT].includes(props.paymentStatus)) {
      $segmentEvent.PAYMENT_MODAL_ERROR(props.paymentStatus);
    }
  });
  const header = computed<TranslateResult>(() => {
    switch (props.paymentStatus) {
      case EPaymentStatus.PROCESSING:
        return i18n.t('payment_processing_title');

      case EPaymentStatus.SUCCESS:
        return i18n.t('payment_order_received_title');

      case EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT:
        return i18n.t('payment_order_not_created_title');

      case EPaymentStatus.FAILURE:
        return i18n.t('payment_failed_title');

      default:
        return '';
    }
  });
  const message = computed<TranslateResult>(() => {
    switch (props.paymentStatus) {
      case EPaymentStatus.PROCESSING:
        return '';

      case EPaymentStatus.SUCCESS:
        return i18n.t('payment_order_received_message');

      case EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT:
        return i18n.t('payment_order_not_created_message');

      case EPaymentStatus.FAILURE:
        return i18n.t('payment_failed_message');

      default:
        return '';
    }
  });
  const ctaText = computed<TranslateResult>(() => {
    switch (props.paymentStatus) {
      case EPaymentStatus.PROCESSING:
        return '';

      case EPaymentStatus.SUCCESS:
        return i18n.t('payment_order_received_cta');

      case EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT:
        return i18n.t('payment_order_not_created_cta');

      case EPaymentStatus.FAILURE:
        return i18n.t('payment_failed_cta');

      default:
        return '';
    }
  });
  const isLoading = computed<boolean>(() => {
    return props.paymentStatus === EPaymentStatus.PROCESSING;
  });

  function onClick(): void {
    if (props.action) {
      props.action();
    }
  }

  return {
    theme,
    header,
    message,
    ctaText,
    isLoading,
    onClick
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;

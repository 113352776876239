




















import { useContext, useRoute, useRouter, useStore, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { EScreenName, EComponentName, ETrackingType, EOriginScreen, getScreenName, EProductPlacement } from '~/lib/segment';
import IRootState from '~/types/app/State';
import { IMarketingBanner } from '~/types/app/MarketingBanner';
import { SET_COLLECTION_PAGE_REFFER } from '~/store/mutation-types';
import { getTargetFromDeeplink } from './helper';
const __sfc_main = {
  name: 'MarketingBannerSliderItem'
};
__sfc_main.props = {
  banner: {
    type: (Object as () => IMarketingBanner),
    default: () => ({})
  },
  position: {
    type: Number,
    default: 0
  },
  decisionId: {
    type: String,
    default: ''
  },
  layoutTrackingId: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const router = useRouter();
  const route = useRoute().value;
  const store = useStore<IRootState>();
  const {
    $segmentEvent,
    localePath
  } = useContext();
  const bannerRef = ref<HTMLDivElement | null>(null);
  const observer = ref<IntersectionObserver | null>(null);
  const startTime = ref<number | null>(null);
  const target = getTargetFromDeeplink(localePath, props.banner);

  function handleSeeAllClick() {
    trackClick(true);
    router.push({
      path: target
    });
  }

  function trackClick(seeAll = false) {
    const screenName = getScreenName(route);
    const {
      id,
      trackingType,
      title,
      slug
    } = props.banner;
    store.commit(`catalog/${SET_COLLECTION_PAGE_REFFER}`, {
      id,
      title,
      type: 'marketingBanner',
      trackingListName: trackingType
    });

    if (props.decisionId) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.sponsoredPlacementClick,
        adDecisionId: props.decisionId,
        screenName,
        componentName: EComponentName.marketingBanner,
        placementId: id,
        placementTitle: title,
        componentPosition: props.position + 1
      });
    } else {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.click,
        screenName,
        componentName: EComponentName.marketingBanner,
        componentContent: id,
        componentVariant: slug,
        componentPosition: props.position + 1,
        ...(seeAll ? {
          eventOrigin: EOriginScreen.seeAll
        } : {})
      });
    }

    if (target.includes('product')) {
      store.dispatch('catalog/setProductViewedTrackingDetails', {
        eventOrigin: EOriginScreen.marketingBanner,
        productPlacement: EOriginScreen.marketingBanner
      });
    }
  }

  onMounted(() => {
    const minimumImpressionTime = 500;

    function handleIntersection(entries: IntersectionObserverEntry[]) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!startTime.value) {
            startTime.value = Date.now();
          }
        } else if (startTime.value && Date.now() - startTime.value >= minimumImpressionTime) {
          $segmentEvent.TRACKING({
            trackingType: ETrackingType.sponsoredPlacementImpression,
            adDecisionId: props.decisionId,
            productPlacement: EProductPlacement.marketingBannerDetails,
            placementTitle: props.banner.title,
            placementId: props.banner.id,
            entryTimestamp: new Date(startTime.value).toISOString(),
            exitTimestamp: new Date().toISOString(),
            componentPosition: props.position + 1,
            screenName: EScreenName.home
          });
          startTime.value = null;
        }
      });
    }

    observer.value = new IntersectionObserver(handleIntersection, {
      threshold: 0.5
    });

    if (!bannerRef.value || !props.decisionId) {
      return;
    }

    observer.value.observe(bannerRef.value);
  });
  onUnmounted(() => {
    observer.value?.disconnect();

    if (!startTime.value) {
      return;
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.sponsoredPlacementImpression,
      adDecisionId: props.decisionId,
      productPlacement: EProductPlacement.marketingBannerDetails,
      placementTitle: props.banner.title,
      placementId: props.banner.id,
      entryTimestamp: new Date(startTime.value).toISOString(),
      exitTimestamp: new Date().toISOString(),
      componentPosition: props.position + 1,
      screenName: EScreenName.home
    });
  });
  return {
    bannerRef,
    target,
    handleSeeAllClick,
    trackClick
  };
};

export default __sfc_main;






























import { useStore, useContext, computed } from '@nuxtjs/composition-api';
import { IAppState, StoreClosedInformation } from '~/types/app/State';
import { translateIfNecessary } from '~/lib/helpers';
import { isHubDeliverable } from '~/store/helpers';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.setup = (__props, __ctx) => {
  const store = useStore<IAppState>();
  const {
    i18n
  } = useContext();
  const boundT = i18n.t.bind(i18n);
  const emit = __ctx.emit;
  const getHubClosedMessage = computed<StoreClosedInformation>(() => store.getters.getHubClosedMessage);
  const isNotDefaultHub = isHubDeliverable(store.state.hub);

  function onStoreClosedModalClose(): void {
    store.dispatch('toggleStoreClosedModal', false);
    emit('accept-store-closed');
  }

  return {
    translateIfNecessary,
    store,
    boundT,
    getHubClosedMessage,
    isNotDefaultHub,
    onStoreClosedModalClose
  };
};

export default __sfc_main;


























import { useContext, defineComponent } from '@nuxtjs/composition-api'
import { ECountries } from '~/types/app/Countries'
import { getCountryFromLocale } from '~/lib/helpers'

export default defineComponent({
  model: {
    prop: 'shown',
    event: 'update',
  },
  props: {
    shown: { type: Boolean, default: false },
    age: { type: String, required: true },
  },
  setup() {
    const { i18n } = useContext()

    const locale = getCountryFromLocale(i18n.locale) as ECountries
    const NLLocale = ECountries.NL

    return {
      locale,
      NLLocale,
    }
  },
})















































import { ECartFeeType, ICartFee, ICartFeeInfo } from '~/types/app/Cart';
import { computed, useContext, useStore } from '@nuxtjs/composition-api';
import { IAppState } from '~/types/app/State';
import { centToEuro, getCurrencyByLocale } from '~/lib/helpers';
import { Currency } from '~/types/app/Price';
import { ESchedulingOptionType } from '~/types/app/PlannedOrders';
import useFee from '~/hooks/useFee';
import { TranslateResult } from 'vue-i18n';
import { getDeliveryFeeTable } from './deliveryFeeInfoModalHelpers';
const __sfc_main = {
  name: 'FeeInfoModal'
};
__sfc_main.props = {
  fees: {
    type: (Array as () => ICartFee[]),
    default: () => []
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n,
    $formatCurrency
  } = useContext();
  const store = useStore<IAppState>();
  const {
    feesForFeeInfo,
    lateNightDescription
  } = useFee();
  const props = __props;
  const emit = __ctx.emit;
  const deliveryTierRows = computed(() => {
    return getDeliveryFeeTable(store.state.deliveryTiersDetails, i18n, $formatCurrency);
  });
  const renderedFees = feesForFeeInfo(props.fees, store.getters.getTotalDeposit);
  const deliveryFeeIndex = renderedFees.findIndex(({
    type
  }) => type === ECartFeeType.DELIVERY_FEE);
  const priorityDeliveryFeeIndex = renderedFees.findIndex(({
    type
  }) => type === ECartFeeType.PRIORITY);
  const expressFeeIndex = renderedFees.findIndex(({
    type
  }) => type === ECartFeeType.EXPRESS);
  const isClickAndCollectOrKiosk = store.getters.getIsClickAndCollect || store.getters.getIsKiosk;
  const deliveryOptionExpressShown = store.getters['plannedOrders/hasDeliveryOptionBeenShown'](ESchedulingOptionType.EXPRESS);
  const deliveryOptionNoRushShown = store.getters['plannedOrders/hasDeliveryOptionBeenShown'](ESchedulingOptionType.NO_RUSH);

  if (!isClickAndCollectOrKiosk && deliveryFeeIndex > -1) {
    if (deliveryOptionExpressShown) {
      if (expressFeeIndex === -1) {
        renderedFees.splice(deliveryFeeIndex + 1, 0, {
          type: ECartFeeType.EXPRESS
        });
      }
    } else if (priorityDeliveryFeeIndex === -1) {
      renderedFees.splice(deliveryFeeIndex + 1, 0, {
        type: ECartFeeType.PRIORITY
      });
    }

    if (deliveryOptionNoRushShown) {
      renderedFees.splice(deliveryFeeIndex + 1, 0, {
        type: ECartFeeType.NO_RUSH
      });
    }
  }

  const amountCurrency = computed<string>(() => {
    const price = {
      amount: store.getters['checkout/storageFeeAmount'] || 0,
      currency: (getCurrencyByLocale(i18n.locale) as Currency),
      decimals: 2
    };
    return $formatCurrency(price);
  });

  function feeTitle(fee: ICartFeeInfo): TranslateResult | undefined {
    const keyMap: Partial<Record<ECartFeeType, string>> = {
      [ECartFeeType.SUP_FEE]: 'order_summary_sup_fee',
      [ECartFeeType.STORAGE_FEE]: 'storage_fee_title',
      [ECartFeeType.DEPOSIT]: 'web_order_summary_deposit',
      [ECartFeeType.LATE_NIGHT]: 'order_summary_late_night_fee',
      [ECartFeeType.PRIORITY]: 'ordering_options_priority_fee_explained_title',
      [ECartFeeType.EXPRESS]: 'ordering_options_express_fee_explained_title',
      [ECartFeeType.NO_RUSH]: 'ordering_options_no_rush_fee_explained_title',
      [ECartFeeType.SMALL_ORDER_FEE]: 'fees_bottomsheet_sof',
      [ECartFeeType.SERVICE_FEE]: 'fees_bottomsheet_sf'
    };
    const key: string | undefined = keyMap[(fee.type as ECartFeeType)];
    return key ? i18n.t(key) : fee.name;
  }

  function feeDescription(fee: ICartFeeInfo): TranslateResult | undefined {
    switch (fee.type) {
      case ECartFeeType.SUP_FEE:
        return i18n.t('order_summary_sup_fee_body');

      case ECartFeeType.STORAGE_FEE:
        return i18n.t('storage_fee_description', {
          amount: amountCurrency.value
        });

      case ECartFeeType.DEPOSIT:
        return i18n.t('deposit_description');

      case ECartFeeType.LATE_NIGHT:
        return lateNightDescription.value;

      case ECartFeeType.PRIORITY:
        return i18n.t('ordering_options_priority_fee_explained_description', {
          amount: $formatCurrency({
            amount: centToEuro(fee.price?.centAmount || 0),
            decimals: 2,
            currency: fee.price?.currency || (getCurrencyByLocale(i18n.locale) as Currency)
          })
        });

      case ECartFeeType.EXPRESS:
        return i18n.t('ordering_options_express_fee_explained_description');

      case ECartFeeType.NO_RUSH:
        return i18n.t('ordering_options_no_rush_fee_explained_description');

      case ECartFeeType.SMALL_ORDER_FEE:
        if (store.state.smallOrderThreshold) {
          return i18n.t('fees_bottomsheet_sof_description', {
            price: $formatCurrency({
              amount: centToEuro(store.state.smallOrderThreshold.centAmount),
              currency: 'EUR'
            })
          });
        }

        return '';

      case ECartFeeType.SERVICE_FEE:
        return i18n.t('fees_bottomsheet_sf_description');

      default:
        return '';
    }
  }

  return {
    ECartFeeType,
    emit,
    deliveryTierRows,
    renderedFees,
    feeTitle,
    feeDescription
  };
};

export default __sfc_main;


















import ICategory from '~/types/app/Category';
import { CATEGORY_SWIMLANE_STYLES } from '~/lib/constants';
const __sfc_main = {
  name: 'CategoriesSwimlane'
};
__sfc_main.props = {
  categories: {
    type: (Array as () => ICategory[]),
    default: () => [],
    required: true
  },
  title: {
    type: String,
    default: undefined,
    required: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const marginRight = CATEGORY_SWIMLANE_STYLES.MARGIN_RIGHT;
  return {
    marginRight
  };
};

export default __sfc_main;

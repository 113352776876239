


























import { TranslateResult } from 'vue-i18n'
import { ROUTES } from '~/lib/routes'
import ICategory from '~/types/app/Category'
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'

interface INavItem {
  name: TranslateResult
  slug?: string
  icon: string
  href?: string
  url?: string
}

export default defineComponent({
  props: {
    categories: { type: Array as () => ICategory[], default: () => [] },
    isUserAuthenticated: { type: Boolean, required: true },
    selectedCategory: { type: String, default: '_na_' },
    selectedSubCategory: { type: String, default: '_na_' },
    deliveryPdt: { type: String, required: true },
  },
  setup() {
    const routeName = ROUTES.CATEGORY
    const { i18n } = useContext()

    const navigation = computed<INavItem[]>(() => {
      return [
        {
          name: i18n.t('navigation_label_home'),
          slug: ROUTES.HOME,
          icon: 'house',
        },
        {
          name: i18n.t('tabview_deals_title'),
          url: ROUTES.DEALS,
          icon: 'deals',
        },
      ]
    })

    const support = computed<INavItem[]>(() => {
      return [
        {
          name: i18n.t('navigation_label_faq'),
          slug: 'faq',
          icon: 'question',
        },

        {
          name: i18n.t('navigation_label_support'),
          href: 'mailto:support@goflink.com',
          icon: 'chat',
        },
      ]
    })

    return {
      navigation,
      routeName,
      support,
    }
  },
})


































import { IAppState } from '~/types/app/State';
import { ref, useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import { IAddressBookAddress, IOnboardingResult } from '~/types/app/Location';
import useLocationOnboarding from '~/hooks/onboarding/useLocationOnboarding';
import { EScreenName, getScreenName, ETrackingType, EComponentName } from '~/lib/segment';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  hideInitialOnboarding: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    $segmentEvent
  } = useContext();
  const store = useStore<IAppState>();
  const route = useRoute();
  const props = __props;
  const emit = __ctx.emit;
  const {
    isLocationSelectionVisible,
    toggleAddressBook,
    toggleOnboardingModal
  } = useLocationOnboarding(props.hideInitialOnboarding);

  async function onUseAddressBookAddress(addressBookAddress: IAddressBookAddress): Promise<void> {
    await toggleAddressBook(false);
    await selectAddressBookAddress(addressBookAddress);
    emit('address-selected');
  }

  function onAddNewAddress(): void {
    toggleAddressBook(false);
    toggleOnboardingModal(true);
  }

  async function onEditAddress({
    address,
    cleanup
  }: {
    address: IAddressBookAddress;
    cleanup: () => void;
  }): Promise<void> {
    await store.dispatch('account/editAddress', address);
    cleanup();
  }

  async function onDeleteAddress({
    addressId,
    cleanup
  }: {
    addressId: IAddressBookAddress['id'];
    cleanup: () => void;
  }): Promise<void> {
    const defaultAddress = store.getters['account/defaultAddress'];

    if (addressId === defaultAddress?.id) {
      return;
    }

    await store.dispatch('account/deleteAddress', addressId);
    cleanup();
  }

  async function selectAddressBookAddress(addressBookAddress: IAddressBookAddress): Promise<void> {
    await store.dispatch('account/syncShippingData', addressBookAddress);
    await store.dispatch('account/setDefaultAddress', addressBookAddress.id);
    await store.dispatch('setShowOnboardingHint', true);
  }

  async function onGoBackFromOnboarding(): Promise<void> {
    store.dispatch('cancelOnboarding');
    await toggleAddressBook(true);
  }

  async function onSucceedOnboarding(onboardingResponse: IOnboardingResult): Promise<void> {
    await store.dispatch('succeedOnboarding', onboardingResponse).then(() => {
      $segmentEvent.ADDRESS_CONFIRMED();

      if (store.getters['account/isUserAuthenticated']) {
        store.dispatch('account/addCurrentAddress');
      }
    });
    await store.dispatch('onOnboardingDone');
    emit('address-selected');
  }

  function onFailOnboarding(): void {
    store.dispatch('failOnboarding');
    store.dispatch('onOnboardingDone');
  }

  function onCancelOnboarding(): void {
    store.dispatch('cancelOnboarding');
    store.dispatch('onOnboardingDone');
  }

  function onSearchInputFocus(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressSearch,
      componentName: EComponentName.searchAddressLine
    });
  }

  function onOnboardingMapDragend(distanceMeters: number): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressRefinement,
      componentName: EComponentName.locationPin,
      componentValue: distanceMeters,
      eventOrigin: getScreenName(route.value)
    });
  }

  const showWaitlistSignupSuccess = ref(false);

  function onWaitlistSignupSuccess() {
    onCancelOnboarding();
    showWaitlistSignupSuccess.value = true;
  }

  function onCloseWaitlistSignupSuccessModal() {
    showWaitlistSignupSuccess.value = false;
  }

  return {
    store,
    isLocationSelectionVisible,
    toggleAddressBook,
    onUseAddressBookAddress,
    onAddNewAddress,
    onEditAddress,
    onDeleteAddress,
    onGoBackFromOnboarding,
    onSucceedOnboarding,
    onFailOnboarding,
    onCancelOnboarding,
    onSearchInputFocus,
    onOnboardingMapDragend,
    showWaitlistSignupSuccess,
    onWaitlistSignupSuccess,
    onCloseWaitlistSignupSuccessModal
  };
};

export default __sfc_main;

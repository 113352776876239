import { centToEuro } from '~/lib/helpers'
import { IDeliveryTiersDetails } from '~/types/app/Hub'
import VueI18n from 'vue-i18n'
import IPrice from '~/types/app/Price'

export const getDeliveryFeeTable = (
  deliveryTiersDetails: IDeliveryTiersDetails[],
  i18n: VueI18n,
  $formatCurrency: (price: IPrice) => string
): Array<Record<string, string>> => {
  if (!deliveryTiersDetails) return []

  return deliveryTiersDetails.map((tier, i, elements) => {
    if (i < elements.length - 1) {
      return {
        range: `${$formatCurrency({
          amount: centToEuro(tier.start.centAmount),
          currency: tier.start.currency,
        })} - ${$formatCurrency({
          amount: centToEuro(tier.end.centAmount),
          currency: tier.start.currency,
        })}`,
        price: `${$formatCurrency({
          amount: centToEuro(tier.deliveryPrice.centAmount),
          currency: tier.deliveryPrice.currency,
        })}`,
      } as Record<string, string>
    } else {
      // Last tier
      return {
        range: `${$formatCurrency({
          amount: centToEuro(tier.start.centAmount),
          currency: tier.start.currency,
        })} ${i18n.t('delivery_fee_order_summary_hint_above')}`,
        price:
          tier.deliveryPrice.centAmount === 0
            ? i18n.t('delivery_fee_order_summary_hint_free')
            : `${$formatCurrency({
                amount: centToEuro(tier.deliveryPrice.centAmount),
                currency: tier.deliveryPrice.currency,
              })}`,
      } as Record<string, string>
    }
  })
}

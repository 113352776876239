import { render, staticRenderFns } from "./StoreClosing.vue?vue&type=template&id=5cd8f24a&"
import script from "./StoreClosing.vue?vue&type=script&lang=ts&"
export * from "./StoreClosing.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ClosingSoonModal: require('/app/build/components/StoreClosing/ClosingSoonModal/ClosingSoonModal.vue').default,StoreClosedModal: require('/app/build/components/StoreClosing/StoreClosedModal/StoreClosedModal.vue').default})

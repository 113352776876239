import { render, staticRenderFns } from "./LocationOnboarding.vue?vue&type=template&id=64c5488d&"
import script from "./LocationOnboarding.vue?vue&type=script&lang=ts&"
export * from "./LocationOnboarding.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AddressBook: require('/app/build/components/onboarding/AddressBook/AddressBook.vue').default,LocationSelection: require('/app/build/components/onboarding/LocationSelection/LocationSelection.vue').default,WaitlistSignupSuccessModal: require('/app/build/components/onboarding/WaitlistSignup/WaitlistSignupSuccessModal.vue').default})

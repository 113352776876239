

























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'shown',
    event: 'update',
  },
  props: {
    shown: { type: Boolean, default: false },
  },
})



































import { useContext, useRoute } from '@nuxtjs/composition-api';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { EComponentContent, EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  address: {
    type: String,
    required: true
  },
  shown: {
    type: Boolean,
    default: true
  },
  isLoading: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const componentName = 'delete_address_dialog';
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute();
  const emit = __ctx.emit;

  function onConfirmDelete(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEdit,
      componentName,
      componentContent: EComponentContent.deleteButton,
      eventOrigin: getScreenName(route.value)
    });
    emit('confirm-delete');
  }

  return {
    emit,
    onConfirmDelete
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;

















































import { useStore, defineComponent, ref, watch } from '@nuxtjs/composition-api'
import { HomeV2Section } from '~/api/transformers/transform-v2-home'
import IRootState from '~/types/app/State'

const SWIMLANES_RENDERED_ON_SERVER_COUNT = 6

export default defineComponent({
  setup() {
    const store = useStore<IRootState>()

    const sections = ref<{
      items: HomeV2Section[]
      visible: { value: boolean }[]
    }>({
      items: [],
      visible: [],
    })

    const fakeDivRefs = ref<Element[]>([])

    watch(
      fakeDivRefs,
      (currentRefs, prevRefs) => {
        if (prevRefs.length > 0) return

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const { target, isIntersecting } = entry

            if (
              target instanceof HTMLElement &&
              target.dataset.index !== undefined &&
              isIntersecting
            ) {
              const index = parseInt(target.dataset.index)

              if (isNaN(index)) return

              sections.value.visible[index].value = true
              observer.unobserve(target)
            }
          })
        })

        currentRefs.forEach((divRef) => observer.observe(divRef))
        return () => observer.disconnect()
      },
      { flush: 'post' }
    )

    watch(
      () => store.state?.catalog?.homepageSections,
      (newSections) => {
        const items = newSections ?? []
        const length = items.length

        sections.value.items = items
        sections.value.visible = Array.from({ length }).map((_, index) => ({
          value: index < SWIMLANES_RENDERED_ON_SERVER_COUNT,
        }))
      },
      { immediate: true }
    )

    return {
      sections,
      fakeDivRefs,
    }
  },
})

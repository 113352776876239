






import {
  ref,
  useMeta,
  useFetch,
  useStore,
  onMounted,
  useContext,
  onBeforeUnmount,
  defineComponent,
} from '@nuxtjs/composition-api'
import { MetaInfo } from 'vue-meta'
import IRootState from '~/types/app/State'
import { metaNoIndex } from '~/lib/helpers'
import DefaultLayout from '~/components/layout/DefaultLayout/DefaultLayout.vue'

export default defineComponent({
  name: 'ShopHomePage',
  components: { DefaultLayout },
  setup() {
    const unsubscribe = ref()

    const store = useStore<IRootState>()
    const { i18n, $config } = useContext()

    useFetch(async () => {
      await store.dispatch('catalog/loadHomepageData')
    })

    useMeta(() => {
      const metaData: MetaInfo['meta'] = [
        {
          hid: 'description',
          name: 'description',
          content: i18n.t('shop_home_description') as string,
        },
        {
          name: 'apple-itunes-app',
          content: `app-id=${$config.appStoreID}`,
        },
        ...metaNoIndex(i18n.locale),
      ]

      return {
        title: i18n.t('shop_home_title') as string,
        meta: metaData,
      }
    })

    onMounted(() => {
      unsubscribe.value = store.subscribeAction({
        after: async (action) => {
          if (action.type === 'adjustToNewHub') {
            await store.dispatch('catalog/loadHomepageData')
          }
        },
      })
    })

    onBeforeUnmount(() => {
      unsubscribe.value && unsubscribe.value()
    })
  },
  head: {},
})

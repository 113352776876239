













export {};
const __sfc_main = {};
__sfc_main.props = {
  disabled: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  },
  fullWidth: {
    type: Boolean,
    default: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  const handleClick = (event: Event) => {
    if (props.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      emit('click', event);
    }
  };

  return {
    handleClick
  };
};

export default __sfc_main;





















import { useContext } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'CartClearedModal'
};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n
  } = useContext();
  const emit = __ctx.emit;
  return {
    i18n,
    emit
  };
};

export default __sfc_main;

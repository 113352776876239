import { render, staticRenderFns } from "./AddressBook.vue?vue&type=template&id=ad9cf8ee&"
import script from "./AddressBook.vue?vue&type=script&lang=ts&"
export * from "./AddressBook.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AddressList: require('/app/build/components/onboarding/AddressBook/AddressList.vue').default,AddressEditModal: require('/app/build/components/onboarding/AddressBook/AddressEditModal.vue').default,AddressDeleteModal: require('/app/build/components/onboarding/AddressBook/AddressDeleteModal.vue').default})












































import { EProductPlacement, EComponentName, ETrackingType, EOriginScreen, getScreenName } from '~/lib/segment';
import { ROUTES } from '~/lib/routes';
import IProduct from '~/types/app/Product';
import IRootState from '~/types/app/State';
import { ICTAButton } from '~/types/app/CTAButton';
import { SET_COLLECTION_PAGE_REFFER } from '~/store/mutation-types';
import { useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import { CollectionPageReferrerType } from '~/store/catalog';
const __sfc_main = {
  name: 'CollectionCard'
};
__sfc_main.props = {
  products: {
    type: (Array as () => IProduct[]),
    default: () => [],
    required: true
  },
  position: {
    type: Number,
    default: 0
  },
  id: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  slug: {
    type: String,
    default: ''
  },
  imageURL: {
    type: String,
    default: ''
  },
  listName: {
    type: String,
    default: ''
  },
  button: {
    type: (Object as () => ICTAButton),
    default: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const routeName = ROUTES.COLLECTION;
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute().value;
  const store = useStore<IRootState>();

  function trackClick(seeAll = false) {
    const screenName = getScreenName(route);
    store.commit(`catalog/${SET_COLLECTION_PAGE_REFFER}`, {
      id: props.id,
      title: props.title,
      trackingListName: props.listName,
      type: CollectionPageReferrerType.collectionCard
    });
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.collectionCard,
      componentVariant: props.title,
      componentPosition: props.position + 1,
      componentContent: props.id,
      screenName,
      ...(seeAll ? {
        eventOrigin: EOriginScreen.seeAllProducts
      } : {})
    });
  }

  return {
    EProductPlacement,
    routeName,
    trackClick
  };
};

export default __sfc_main;





















import { useStore, watch, computed, useContext, ref } from '@nuxtjs/composition-api';
import { IAppState } from '~/types/app/State';
import { Currency } from '~/types/app/Price';
import { getCurrencyByLocale } from '~/lib/helpers';
import { EComponentName, EComponentVariant } from '~/lib/segment';
import { EBannerMessageVariant } from '~/types/app/BannerMessage';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n,
    $formatCurrency,
    $segmentEvent
  } = useContext();
  const store = useStore<IAppState>();
  const showAlert = ref(false);
  watch(() => store.state.checkout.hadStorageFee, storageFee => {
    showAlert.value = storageFee;
  });
  watch(() => store.getters['checkout/storageFeeAmount'], (amount, amountBefore) => {
    if (amount === 0) {
      showAlert.value = false;

      if (amountBefore > 0) {
        store.dispatch('checkout/updateHadStorageFee', false);
      }
    }
  });
  watch(() => showAlert.value, isVisible => {
    if (isVisible) {
      let content = storageFeeText.value;
      content += ` ${i18n.t('storage_fee_banner_cta')}`;
      $segmentEvent.SECTION_VIEWED_ON_CURRENT_SCREEN({
        componentName: EComponentName.feeBanner,
        componentVariant: EComponentVariant.storageFee,
        componentContent: content
      });
    }
  });
  const storageFeeText = computed<string>(() => {
    const price = {
      amount: store.getters['checkout/storageFeeAmount'] || 0,
      currency: (getCurrencyByLocale(i18n.locale) as Currency),
      decimals: 2
    };
    const amount = $formatCurrency(price);
    return (i18n.t('storage_fee_banner', {
      amount
    }) as string);
  });
  return {
    EBannerMessageVariant,
    showAlert,
    storageFeeText
  };
};

export default __sfc_main;

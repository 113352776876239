





















































import { ref, toRefs } from '@nuxtjs/composition-api';
import VueHorizontal from 'vue-horizontal';
import useSlider from '~/hooks/useSlider';
import { IVueHorizontal } from '~/types/app/Slider';
const __sfc_main = {};
__sfc_main.props = {
  perPage: {
    type: Number,
    default: 1
  },
  itemGap: {
    type: String,
    default: '1rem'
  },
  snap: {
    type: String,
    default: 'center'
  },
  showDots: {
    type: Boolean,
    default: false
  },
  draggable: {
    type: Boolean,
    default: false
  },
  showArrows: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const horizontalRef = ref<IVueHorizontal | null>(null);
  const {
    perPage: perPageRef,
    snap: snapRef,
    showDots: showDotsRef,
    draggable: draggableRef,
    showArrows: showArrowsRef
  } = toRefs(props);
  const {
    currentIndex,
    pages,
    currentSnapping,
    containsNonSliderItems,
    itemWidth,
    showArrowLeft,
    showArrowRight,
    changePage,
    slideRight,
    slideLeft,
    onScrollDebounce,
    onScroll,
    dragStart
  } = useSlider({
    perPage: perPageRef,
    itemGap: props.itemGap,
    snap: snapRef,
    draggable: draggableRef,
    horizontalRef
  });
  return {
    horizontalRef,
    showDotsRef,
    showArrowsRef,
    currentIndex,
    pages,
    currentSnapping,
    containsNonSliderItems,
    itemWidth,
    showArrowLeft,
    showArrowRight,
    changePage,
    slideRight,
    slideLeft,
    onScrollDebounce,
    onScroll,
    dragStart
  };
};

__sfc_main.components = Object.assign({
  VueHorizontal
}, __sfc_main.components);
export default __sfc_main;




























const __sfc_main = {
  name: 'WaitlistSignupSuccessModal'
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};

export default __sfc_main;




















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { ICartAddPayload } from '~/types/app/State'
import { EAgeVerificationTag } from '~/types/app/AgeVerification'

export default defineComponent({
  props: {
    verificationTag: {
      type: String,
      default: '',
    },
    payload: {
      type: Object as () => ICartAddPayload,
      required: true,
    },
  },
  setup(props) {
    const alcoholModalAges = ['16', '18']
    const showTobaccoModal = computed<boolean>(
      () => props.verificationTag === EAgeVerificationTag.TOBACCO18
    )

    function showAlcoholModal(age: string): boolean {
      switch (age) {
        case '16':
          return props.verificationTag === EAgeVerificationTag.ALCOHOL16
        case '18':
          return props.verificationTag === EAgeVerificationTag.ALCOHOL18
        default:
          return false
      }
    }

    return {
      alcoholModalAges,
      showTobaccoModal,
      showAlcoholModal,
    }
  },
})

























import { useContext, onMounted } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'CartErrorModal'
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    $segmentEvent
  } = useContext();
  onMounted(() => {
    $segmentEvent.GENERIC_ERROR_MODAL_SHOWN();
  });
  const emit = __ctx.emit;
  return {
    emit
  };
};

export default __sfc_main;




































import { computed, onMounted, ref, useContext, useStore } from '@nuxtjs/composition-api';
import { IAddressBookAddress } from '~/types/app/Location';
import { EComponentContent, ETrackingType } from '~/lib/segment';
import { ISelectedAddressBookAddress } from '~/types/app/Onboarding';
import { IAppState } from '~/types/app/State';
enum AddressBookState {
  List = 'List',
  Edit = 'Edit',
  Delete = 'Delete',
}
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  addresses: {
    type: (Array as () => IAddressBookAddress[]),
    default: () => []
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const componentName = 'address_book_modal';
  const {
    $segmentEvent
  } = useContext();
  const store = useStore<IAppState>();
  const props = __props;
  const emit = __ctx.emit;
  const state = ref<AddressBookState>(AddressBookState.List);
  const isLoading = ref<boolean>(false);
  const editCandidate = ref<IAddressBookAddress>();
  onMounted(() => {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.sectionViewed,
      componentName,
      componentContent: props.addresses?.length,
      screenName: ''
    });
  });
  const editCandidateFormatted = computed<string>(() => {
    const address = editCandidate.value;
    return address ? `${address.street_address}, ${address.post_code} ${address.city}` : '';
  });

  function onChangeCurrentAddress(selectedAddress: ISelectedAddressBookAddress): void {
    const {
      address: addressBookAddress,
      position
    } = selectedAddress;
    trackAddressButtonClicked({
      componentContent: addressBookAddress === store.getters['account/defaultAddress'] ? EComponentContent.activeAddressOption : EComponentContent.alternativeAddressOption,
      position
    });
    emit('change-current-address', addressBookAddress);
  }

  function onAddNewAddress() {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName,
      componentContent: EComponentContent.addNewAddressButton,
      screenName: ''
    });
    emit('add-new-address');
  }

  function onPromptAddressDelete(selectedAddress: ISelectedAddressBookAddress): void {
    trackAddressButtonClicked({
      componentContent: EComponentContent.deleteButton,
      position: selectedAddress.position
    });
    editCandidate.value = selectedAddress.address;
    state.value = AddressBookState.Delete;
  }

  function onEditAddress(selectedAddress: ISelectedAddressBookAddress): void {
    trackAddressButtonClicked({
      componentContent: EComponentContent.editButton,
      position: selectedAddress.position
    });
    editCandidate.value = selectedAddress.address;
    state.value = AddressBookState.Edit;
  }

  function onSaveChanges(editedAddress: IAddressBookAddress): void {
    isLoading.value = true;

    const cleanup = () => {
      isLoading.value = false;
      editCandidate.value = undefined;
      state.value = AddressBookState.List;
    };

    emit('edit-address', {
      address: editedAddress,
      cleanup
    });
  }

  function confirmDelete(): void {
    if (!editCandidate.value) {
      return;
    }

    isLoading.value = true;

    const cleanup = () => {
      isLoading.value = false;
      editCandidate.value = undefined;
      state.value = AddressBookState.List;
    };

    emit('delete-address', {
      addressId: editCandidate.value.id,
      cleanup
    });
  }

  function cancelDelete(): void {
    state.value = AddressBookState.Edit;
  }

  function trackAddressButtonClicked({
    componentContent,
    position
  }: {
    componentContent: string;
    position: number;
  }): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName,
      componentContent,
      componentValue: position,
      screenName: ''
    });
  }

  return {
    AddressBookState,
    emit,
    state,
    isLoading,
    editCandidate,
    editCandidateFormatted,
    onChangeCurrentAddress,
    onAddNewAddress,
    onPromptAddressDelete,
    onEditAddress,
    onSaveChanges,
    confirmDelete,
    cancelDelete
  };
};

export default __sfc_main;

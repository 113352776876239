

































































import { IAddressBookAddress } from '~/types/app/Location';
import VIcon from '~/components/VIcon/VIcon.vue';
import { ISelectedAddressBookAddress } from '~/types/app/Onboarding';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  addresses: {
    type: (Array as () => IAddressBookAddress[]),
    required: true
  },
  canEdit: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  function getRowClass(rowIdx: number): Record<string, boolean> {
    return {
      '--last-row': rowIdx === props.addresses.length - 1
    };
  }

  return {
    emit,
    getRowClass
  };
};

__sfc_main.components = Object.assign({
  VIcon
}, __sfc_main.components);
export default __sfc_main;

















































































































































import { ref, watch, useRoute, useStore, onMounted, useRouter, useContext, onBeforeUnmount, computed } from '@nuxtjs/composition-api';
import { RawLocation } from 'vue-router';
import { ETrackingType, getScreenName, EComponentName } from '~/lib/segment';
import { ROUTES } from '~/lib/routes';
import { ROUTE_LOCALE_SEPARATOR } from '~/lib/constants';
import IMenuItem from '~/types/app/MenuItem';
import { IAppState } from '~/types/app/State';
import { EClosureCase } from '~/types/app/Hub';
import { ESchedulingOptionType } from '~/types/app/PlannedOrders';
import FlinkLogo from '~/assets/logo.svg?inline';
import { updateDeliveryInformation } from '~/api/services/update-delivery-information';
import useLoadHubOnStorePage from '~/hooks/useLoadHubOnStorePage';
import ErrorLayout from '~/components/layout/ErrorLayout/ErrorLayout.vue';
import { CONSENT_CONTAINER_ID } from '~/plugins/segment.client.js';
import { usePrepareCartForCheckout } from '~/hooks/checkout/usePrepareCartForCheckout';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  name: 'DefaultLayout'
});

__sfc_main.props = {
  error: {
    type: (Object as () => Error | null),
    default: null
  },
  headerFixed: {
    type: Boolean,
    default: true
  },
  page: {
    type: String,
    default: ''
  },
  searchInputGrow: {
    type: Boolean,
    default: true
  },
  slug: {
    type: String,
    default: ''
  },
  isHamburgerMenu: {
    type: Boolean,
    default: false
  },
  hideMenuButton: {
    type: Boolean,
    default: false
  },
  hideDeliveryAddress: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  useLoadHubOnStorePage();
  const router = useRouter();
  const store = useStore<IAppState>();
  const scrollbarWidth = ref<number>(0);
  const showCategoryMenu = ref(false);
  const unsubscribe = ref<() => void>();
  const route = useRoute().value;
  const {
    areItemsUnavailable,
    unknownError
  } = usePrepareCartForCheckout();
  const {
    $apiCartV3,
    i18n,
    $segmentIdentify,
    $segmentEvent,
    localePath,
    $logger
  } = useContext();
  const homeRouteName = ref<RawLocation | string>(localePath(ROUTES.HOME));
  const showTimeSlotModal = ref<boolean>(false);
  const isKiosk = computed(() => store.getters.getIsKiosk);

  function updateScrollBarWidth(): void {
    scrollbarWidth.value = window.innerWidth - document.body.clientWidth;
  }

  function handleCategoryMenuToggle(): void {
    showCategoryMenu.value = !showCategoryMenu.value;
  }

  function onAcceptStoreClosed(): void {
    if (store.state.hub?.details.closure?.case !== EClosureCase.TEMPORARY) {
      router.push({
        path: localePath(ROUTES.HOME)
      });
    }
  }

  function hideCategoryMenu(): void {
    showCategoryMenu.value = false;
  }

  function onSelectCategory({
    id = '',
    name
  }: IMenuItem, index: number): void {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.category,
      eventOrigin: EComponentName.categoryMenu,
      componentContent: id,
      componentPosition: index + 1,
      componentVariant: name,
      screenName
    });
  }

  function onSelectSubCategory({
    item,
    topLevelItem
  }: {
    item: IMenuItem;
    topLevelItem: IMenuItem;
  }): void {
    store.dispatch('selectSubCategory', item.slug);
    $segmentEvent.CATEGORY_SELECTED({
      categoryId: (topLevelItem.id as string),
      categoryName: topLevelItem.name,
      subCategoryId: (item.id as string),
      subCategoryName: item.name
    });
  }

  function onLogoClicked(): void {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.flinkLogo,
      screenName
    });
  }

  async function onOpenTimeslotSelection() {
    showTimeSlotModal.value = true;
    $segmentEvent.DELIVERY_TIME_SELECTION_VIEWED();
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.pdtButton,
      screenName: getScreenName(route)
    });

    if (store.state.deliveryCoordinates) {
      await store.dispatch('plannedOrders/updateSchedulingOptions');
    }
  }

  const onSelectToken = async ({
    token,
    type
  }: {
    token: string;
    type: ESchedulingOptionType;
  }): Promise<void> => {
    store.dispatch('plannedOrders/selectTimeSlotToken', {
      id: token
    });
    await store.dispatch('plannedOrders/updateSchedulingHome');
    await updateDeliveryInformation({
      client: $apiCartV3,
      logger: $logger,
      locale: i18n.locale,
      hubSlug: store.state.hub?.slug || '',
      cartId: store.state.cartId || '',
      updatedSlot: {
        id: token,
        type
      }
    });
    $segmentEvent.WEB_OPENED();
    showTimeSlotModal.value = false;
  };

  const enablePlannedOrders = async () => {
    if (store.getters['plannedOrders/isPlannedOrdersStale'](router.currentRoute.name?.split(ROUTE_LOCALE_SEPARATOR)[0])) {
      await store.dispatch('plannedOrders/updateSchedulingHome');

      if (store.state.deliveryCoordinates) {
        store.dispatch('updateIsHubUpdateNeeded', true);
      }

      $segmentEvent.WEB_OPENED();
    }

    unsubscribe.value = store.subscribeAction({
      after: async action => {
        if (action.type === 'adjustToNewHub' && store.state.plannedOrders.featureEnabled) {
          store.dispatch('plannedOrders/setShownMessageId');
          await store.dispatch('plannedOrders/updateSchedulingHome');
          $segmentEvent.WEB_OPENED();
        }
      }
    });
  };

  watch(() => store.state.selectedSubCategory, function (): void {
    showCategoryMenu.value = false;
  });
  onMounted(() => {
    $segmentIdentify();
    updateScrollBarWidth();
    window.addEventListener('resize', updateScrollBarWidth);
    enablePlannedOrders();

    if (isKiosk.value) {
      const cookieConsentElement = (document?.querySelector(CONSENT_CONTAINER_ID) as HTMLElement);

      if (cookieConsentElement) {
        cookieConsentElement.style.display = 'none';
      }
    }
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScrollBarWidth);
    if (unsubscribe.value) unsubscribe.value();
  });
  return {
    store,
    scrollbarWidth,
    showCategoryMenu,
    areItemsUnavailable,
    unknownError,
    homeRouteName,
    showTimeSlotModal,
    isKiosk,
    handleCategoryMenuToggle,
    onAcceptStoreClosed,
    hideCategoryMenu,
    onSelectCategory,
    onSelectSubCategory,
    onLogoClicked,
    onOpenTimeslotSelection,
    onSelectToken
  };
};

__sfc_main.components = Object.assign({
  ErrorLayout,
  FlinkLogo
}, __sfc_main.components);
export default __sfc_main;



























import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  currentId: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  message: {
    type: String,
    default: ''
  },
  ctaText: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};

export default __sfc_main;

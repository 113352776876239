import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=4a23e5c5&scoped=true&"
import script from "./Drawer.vue?vue&type=script&lang=ts&"
export * from "./Drawer.vue?vue&type=script&lang=ts&"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=4a23e5c5&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a23e5c5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/app/build/components/Menu/Menu.vue').default})

import { onMounted, useStore, watch } from '@nuxtjs/composition-api'
import { IAppState } from '~/types/app/State'

export default function useLoadHubOnStorePage(): void {
  const store = useStore<IAppState>()

  onMounted(loadHubIfNecessary)
  watch(() => store.state.isHubUpdateNeeded, loadHubIfNecessary)

  async function loadHubIfNecessary(): Promise<void> {
    if (store.state.isHubUpdateNeeded) {
      await store.dispatch('loadHub')
      await store.dispatch(
        'subscription/getSubscriptionEligibilityStatus',
        { forceReload: true },
        { root: true }
      )
      store.dispatch('testStoreOpeningHours')
      await store.dispatch(
        'subscription/getSubscriptionStatus',
        { forceReload: true },
        { root: true }
      )
    }
  }
}

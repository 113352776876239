import { render, staticRenderFns } from "./ItemUnavailableModal.vue?vue&type=template&id=497f141e&scoped=true&"
import script from "./ItemUnavailableModal.vue?vue&type=script&lang=ts&"
export * from "./ItemUnavailableModal.vue?vue&type=script&lang=ts&"
import style0 from "./ItemUnavailableModal.vue?vue&type=style&index=0&id=497f141e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "497f141e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})

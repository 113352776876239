
































import ICategory from '~/types/app/Category';
import { ROUTES } from '~/lib/routes';
import { useContext, useRoute } from '@nuxtjs/composition-api';
import { ETrackingType, getScreenName, EComponentName } from '~/lib/segment';
import { CATEGORY_SWIMLANE_STYLES } from '~/lib/constants';
const __sfc_main = {
  name: 'CategoryCard'
};
__sfc_main.props = {
  category: {
    type: (Object as () => ICategory),
    required: true
  },
  index: {
    type: Number,
    default: 0,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const routeName = ROUTES.CATEGORY;
  const thumbnailWidth = CATEGORY_SWIMLANE_STYLES.THUMBNAIL_WIDTH;
  const thumbnailHeight = CATEGORY_SWIMLANE_STYLES.THUMBNAIL_HEIGHT;
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute();

  function handleClick({
    id,
    name
  }: ICategory): void {
    const screenName = getScreenName(route.value);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      eventOrigin: 'category_swimlane',
      componentName: EComponentName.category,
      componentContent: id,
      componentVariant: name,
      componentPosition: props.index,
      screenName
    });
  }

  return {
    routeName,
    thumbnailWidth,
    thumbnailHeight,
    handleClick
  };
};

export default __sfc_main;

import { render, staticRenderFns } from "./MarketingBannerSliderItem.vue?vue&type=template&id=83583b48&scoped=true&"
import script from "./MarketingBannerSliderItem.vue?vue&type=script&lang=ts&"
export * from "./MarketingBannerSliderItem.vue?vue&type=script&lang=ts&"
import style0 from "./MarketingBannerSliderItem.vue?vue&type=style&index=0&id=83583b48&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83583b48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerContent: require('/app/build/components/MarketingBannerSlider/BannerContent.vue').default})

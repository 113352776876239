import { render, staticRenderFns } from "./AgeVerificationTobacco.vue?vue&type=template&id=8f144692&scoped=true&"
import script from "./AgeVerificationTobacco.vue?vue&type=script&lang=ts&"
export * from "./AgeVerificationTobacco.vue?vue&type=script&lang=ts&"
import style0 from "./AgeVerificationTobacco.vue?vue&type=style&index=0&id=8f144692&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f144692",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})

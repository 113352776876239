import { ROUTES, PROFILE_PAGE_TABS } from '~/lib/routes'
import { IMarketingBanner } from '~/types/app/MarketingBanner'
import { RawLocation } from 'vue-router'

const INVITE_FRIENDS_TAB_SHORT_NAME = 'raf'

const deeplinkPageMapping: Record<string, ROUTES> = {
  cart: ROUTES.CART,
  category: ROUTES.CATEGORY,
  collection: ROUTES.COLLECTION,
  deals: ROUTES.DEALS,
  home: ROUTES.HOME,
  'last-bought-detail': ROUTES.LAST_BOUGHT,
  prime: ROUTES.PRIME_JOIN,
  product: ROUTES.PRODUCT,
  [INVITE_FRIENDS_TAB_SHORT_NAME]: ROUTES.PROFILE,
}

export function getTargetFromDeeplink(
  localePath: (route: RawLocation) => string,
  banner: IMarketingBanner
): string {
  const regex = /flink:\/\/([^/]+)(?:\/([^/]+))?(?:\/([^/]+))?/
  let target = localePath({
    name: ROUTES.COLLECTION,
    params: { slug: banner.slug },
  })

  const bannerDeeplink = banner.deepLink
  if (bannerDeeplink) {
    const match = bannerDeeplink.match(regex)
    const [, ...groups] = match || []

    // for subcategory url we will have 3 match group ['category', 'categoryid', 'subcategoryid']
    // therefore groups.length should be 3
    if (groups[0] === 'category' && groups.length === 3) {
      target = localePath({
        name: deeplinkPageMapping[groups[0]] || ROUTES.HOME,
        params: {
          category: groups[1],
          slug: groups[2],
        },
      })
    } else if (groups[0]) {
      target = localePath({
        name: deeplinkPageMapping[groups[0]] || ROUTES.HOME,
        params: {
          slug: groups[1],
        },
        query: {
          ...(groups[0] === INVITE_FRIENDS_TAB_SHORT_NAME
            ? { tab: PROFILE_PAGE_TABS.INVITE_FRIENDS }
            : {}),
        },
      })
    }
  }
  return target
}

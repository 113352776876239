

























import { IMarketingBanner } from '~/types/app/MarketingBanner';
const __sfc_main = {
  name: 'BannerContent'
};
__sfc_main.props = {
  banner: {
    type: (Object as () => IMarketingBanner),
    default: () => ({})
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};

export default __sfc_main;

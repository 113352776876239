import { render, staticRenderFns } from "./FeeInfoModal.vue?vue&type=template&id=a7374f12&scoped=true&"
import script from "./FeeInfoModal.vue?vue&type=script&lang=ts&"
export * from "./FeeInfoModal.vue?vue&type=script&lang=ts&"
import style0 from "./FeeInfoModal.vue?vue&type=style&index=0&id=a7374f12&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7374f12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})

import { VNode } from 'vue'
import { ScrollParams, ISliderProps, ISlider } from '~/types/app/Slider'
import {
  ref,
  Ref,
  watch,
  computed,
  onMounted,
  getCurrentInstance,
} from '@nuxtjs/composition-api'

export default function useSlider({
  perPage,
  itemGap,
  snap,
  draggable,
  horizontalRef,
}: ISliderProps): ISlider {
  const left = ref(0)
  const width = ref(0)
  const originX = ref(0)
  const isDragging = ref(false)
  const originLeft = ref(0)
  const currentIndex = ref(0)
  const scopedSlots = ref([]) as Ref<VNode[]>
  const pages = ref(0)
  const vm = getCurrentInstance()?.proxy

  const items = computed(() => {
    return scopedSlots.value
  })

  const currentSnapping = computed<string>(() => {
    return isDragging.value ? 'none' : snap.value
  })

  const itemsPerPage = computed((): number => {
    const count = perPage.value <= 0 ? 1 : perPage.value
    return items.value.length && count > items.value.length
      ? items.value.length
      : count
  })

  const containsNonSliderItems = computed((): boolean => {
    return items.value.some((node: any) => {
      return !node.tag?.includes('VSliderItem')
    })
  })

  const itemWidth = computed((): string => {
    return `calc((100% - (${itemsPerPage.value - 1} * ${itemGap})) / ${
      itemsPerPage.value
    })`
  })

  const showArrowLeft = computed((): boolean => {
    return currentIndex.value > 0
  })

  const showArrowRight = computed((): boolean => {
    return currentIndex.value < pages.value - 1
  })

  const changePage = (index: number): void => {
    if (!horizontalRef.value) {
      return
    }
    if (index === pages.value - 1) {
      horizontalRef.value.scrollToIndex(items.value.length - 1)
    } else {
      horizontalRef.value.scrollToLeft(index * width.value)
    }
  }

  const slideRight = (): void => {
    if (currentIndex.value < pages.value - 1) {
      setTimeout(() => {
        changePage(currentIndex.value + 1)
      }, 100)
    }
  }

  const slideLeft = (): void => {
    if (currentIndex.value > 0) {
      setTimeout(() => {
        changePage(currentIndex.value - 1)
      }, 100)
    }
  }

  const onScrollDebounce = ({
    scrollWidth,
    width: currentWidth,
  }: Pick<ScrollParams, 'scrollWidth' | 'width'>): void => {
    const currentPages = scrollWidth / currentWidth

    width.value = currentWidth
    pages.value = currentPages > 1 ? Math.max(Math.round(currentPages), 2) : 1
  }

  const onScroll = ({
    left: currentLeft,
  }: Pick<ScrollParams, 'left'>): void => {
    left.value = currentLeft

    const index = currentLeft / width.value
    const newIndex = index > 0 ? Math.max(Math.round(index), 1) : 0

    if (newIndex !== currentIndex.value) {
      vm?.$emit('swipe', newIndex > currentIndex.value ? 'next' : 'back')
    }
    currentIndex.value = newIndex
  }

  const dragStart = (e: MouseEvent): void => {
    if (draggable?.value) {
      originX.value = e.pageX
      originLeft.value = left.value
      isDragging.value = true

      document.addEventListener('mouseup', dragEnd)
      document.addEventListener('mousemove', dragMove)
    }
  }

  const dragMove = (e: MouseEvent): void => {
    horizontalRef.value?.scrollToLeft(
      originLeft.value - (e.pageX - originX.value),
      'auto'
    )
  }

  const dragEnd = (): void => {
    originX.value = 0
    isDragging.value = false

    document.removeEventListener('mouseup', dragEnd)
    document.removeEventListener('mousemove', dragMove)
  }

  onMounted((): void => {
    scopedSlots.value = horizontalRef.value?.$slots.default ?? ([] as VNode[])
    pages.value = scopedSlots.value.length / perPage.value
    pages.value = pages.value > 1 ? Math.max(Math.round(pages.value), 2) : 1
  })

  watch(
    () => draggable?.value,
    (): void => {
      dragEnd()
    }
  )

  watch(
    () => perPage.value,
    (): void => {
      horizontalRef.value?.refresh(onScrollDebounce)
    }
  )

  watch(
    () => snap.value,
    (): void => {
      horizontalRef.value?.refresh(onScrollDebounce)
    }
  )

  return {
    left,
    width,
    originX,
    isDragging,
    originLeft,
    currentIndex,
    scopedSlots,
    pages,
    items,
    currentSnapping,
    itemsPerPage,
    containsNonSliderItems,
    itemWidth,
    showArrowLeft,
    showArrowRight,
    changePage,
    slideRight,
    slideLeft,
    onScrollDebounce,
    onScroll,
    dragStart,
    dragMove,
    dragEnd,
  }
}

import { render, staticRenderFns } from "./ButtonSelection.vue?vue&type=template&id=5fabdf7c&scoped=true&"
import script from "./ButtonSelection.vue?vue&type=script&lang=ts&"
export * from "./ButtonSelection.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonSelection.vue?vue&type=style&index=0&id=5fabdf7c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fabdf7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default})




















import { EComponentName, ETrackingType, getScreenName } from '~/lib/segment';
import { IMarketingBanner } from '~/types/app/MarketingBanner';
import { useContext, useRoute } from '@nuxtjs/composition-api';
import MarketingBannerSliderItem from './MarketingBannerSliderItem.vue';
const __sfc_main = {
  name: 'MarketingBannerSlider'
};
__sfc_main.props = {
  marketingBanners: {
    type: (Array as () => IMarketingBanner[]),
    default: () => []
  },
  decisionId: {
    type: String,
    default: ''
  },
  layoutTrackingId: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute().value;

  function handleSwipe(direction: string) {
    if (!props.decisionId) {
      const screenName = getScreenName(route);
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.swipe,
        screenName,
        componentName: EComponentName.marketingBanner,
        componentVariant: EComponentName.marketingBanner,
        componentContent: direction
      });
    }
  }

  return {
    handleSwipe
  };
};

__sfc_main.components = Object.assign({
  MarketingBannerSliderItem
}, __sfc_main.components);
export default __sfc_main;

import { render, staticRenderFns } from "./CollectionsSwimlaneV2.vue?vue&type=template&id=7eafce60&scoped=true&"
import script from "./CollectionsSwimlaneV2.vue?vue&type=script&lang=ts&"
export * from "./CollectionsSwimlaneV2.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionsSwimlaneV2.vue?vue&type=style&index=0&id=7eafce60&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eafce60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VCloudinaryImage: require('/app/build/components/VCloudinaryImage/VCloudinaryImage.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,VSwimlane: require('/app/build/components/VSwimlane/VSwimlane.vue').default})

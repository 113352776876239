import { render, staticRenderFns } from "./CategoryCard.vue?vue&type=template&id=fc412ed6&scoped=true&"
import script from "./CategoryCard.vue?vue&type=script&lang=ts&"
export * from "./CategoryCard.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryCard.vue?vue&type=style&index=0&id=fc412ed6&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc412ed6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VCloudinaryImage: require('/app/build/components/VCloudinaryImage/VCloudinaryImage.vue').default})

import { render, staticRenderFns } from "./CartClearedModal.vue?vue&type=template&id=61f3884e&scoped=true&"
import script from "./CartClearedModal.vue?vue&type=script&lang=ts&"
export * from "./CartClearedModal.vue?vue&type=script&lang=ts&"
import style0 from "./CartClearedModal.vue?vue&type=style&index=0&id=61f3884e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f3884e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})

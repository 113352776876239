








































































import { computed, ref, useContext, watch } from '@nuxtjs/composition-api';
import { ESchedulingOptionType, ISchedulingOption } from '~/types/app/PlannedOrders';
import { theme } from '~/tailwind.config';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { EComponentName, EComponentVariant, EScreenName, ETrackingType } from '~/lib/segment';
const __sfc_main = {};
__sfc_main.props = {
  schedulingOptions: {
    type: (Array as () => ISchedulingOption[]),
    required: true
  },
  selectedTimeslotId: {
    type: String,
    default: ''
  },
  enableAsapSelection: {
    type: Boolean,
    default: true
  },
  asapToken: {
    type: String,
    required: true
  },
  asapPdt: {
    type: Number,
    default: 12
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const activeToken = ref<string>(props.selectedTimeslotId);
  const timeSlotsShown = ref<number>(5);
  const {
    $segmentEvent
  } = useContext();
  const showLaterButton = computed<boolean>(() => props.schedulingOptions.length > timeSlotsShown.value + 1 && selectedTimeslotIdx.value < timeSlotsShown.value);
  const selectedTimeslotIdx = computed<number>(() => props.schedulingOptions.findIndex(item => item.id === props.selectedTimeslotId));
  const visibleTimeSlots = computed<ISchedulingOption[]>(() => {
    if (!showLaterButton.value) {
      return props.schedulingOptions;
    }

    return props.schedulingOptions.slice(0, timeSlotsShown.value);
  });
  const isAsapSelected = computed<boolean>(() => activeToken.value === props.asapToken);
  const currentSelectedPlanned = computed<ISchedulingOption | undefined>(() => {
    if (!activeToken.value || activeToken.value === props.asapToken) return undefined;
    return props.schedulingOptions.find(item => item.id === activeToken.value);
  });

  function showLaterOptions(): void {
    timeSlotsShown.value = props.schedulingOptions.length;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.laterToday,
      screenName: EScreenName.deliveryTimeSelection
    });
  }

  function selectToken(id: string): void {
    activeToken.value = id;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.deliveryOption,
      screenName: EScreenName.deliveryTimeSelection,
      componentVariant: isAsapSelected.value ? EComponentVariant.asapDelivery : EComponentVariant.plannedOrder,
      componentContent: currentSelectedPlanned.value ? JSON.stringify({
        start: currentSelectedPlanned.value.meta?.start,
        end: currentSelectedPlanned.value.meta?.end
      }) : ''
    });
  }

  function confirmTokenSelection(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.confirmationButton,
      screenName: EScreenName.deliveryTimeSelection,
      componentVariant: isAsapSelected.value ? EComponentVariant.asapDelivery : EComponentVariant.plannedOrder,
      componentContent: currentSelectedPlanned.value ? JSON.stringify({
        start: currentSelectedPlanned.value.meta?.start,
        end: currentSelectedPlanned.value.meta?.end
      }) : ''
    });
    emit('select-token', {
      token: activeToken.value,
      type: isAsapSelected.value ? ESchedulingOptionType.ASAP : ESchedulingOptionType.PLANNED
    });
  }

  watch(() => props.selectedTimeslotId, () => {
    activeToken.value = props.selectedTimeslotId;
  });
  return {
    theme,
    emit,
    activeToken,
    showLaterButton,
    visibleTimeSlots,
    showLaterOptions,
    selectToken,
    confirmTokenSelection
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;
